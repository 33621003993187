<template>
  
    <section class="row px-2">
     
        <div class="col-12">
          <div class="d-flex pt-3 justify-content-between">
            <div>
              <p class="h4" style="font-weight:bold;">พิมพ์ใบเสนอราคาแบบกำหนดเอง</p>
            </div>
            <div >
              <button @click="saveQuotationHeader" :class="`btn-visible-${buCode ? buCode : getBU}`" >บันทึก</button>
            </div>
          </div>
        </div>
        <div  class="col-12 card">
          <div class="row ">
            <div class="form-group col-6">
                <label for="nameadd">ชื่อ-นามสกุล</label>
                <input v-model="formData.nameU" type="text" class="form-control" name="nameadd"  >
            </div>
            <div class="form-group col-6">
                <label for="phoneU">โทรศัพท์ติดต่อ</label>
                <input v-model="formData.phoneU" type="text" class="form-control" name="phoneU"  >
            </div>
      
            <div class="form-group col-6">
                <label for="emailU">Email</label>
                <input v-model="formData.emailU" type="text" class="form-control" name="emailU" >
            </div>
            <div class="form-group col-6">
                <label for="the1U">หมายเลข The 1</label>
                <input v-model="formData.the1U" type="text" class="form-control" name="the1U" >
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="d-flex pt-3 justify-content-between">
            <div>
              <p class="h4" style="font-weight:bold;">พิมพ์ใบเสนอราคาแบบบริษัท</p>
            </div>
            <div >
              <button @click="saveQuotationHeader" :class="`btn-visible-${buCode ? buCode : getBU}`" >บันทึก</button>
            </div>
          </div>
        </div>
        <div  class="col-12 card">
          <div class="row">
            <div class="form-group col-6">
                <label for="nameC">ชื่อผู้ติดต่อ</label>
                <input v-model="formData.nameC" type="text" class="form-control" name="nameC" :placeholder="formData.nameC">
            </div>
            <div class="form-group col-6">
                <label for="comnameC">ชื่อบริษัท</label>
                <textarea v-model="formData.comnameC" type="text" class="form-control" name="comnameC" :placeholder="formData.comnameC" ></textarea>
            </div>
      
            <div class="form-group col-6">
                <label for="addressC">ที่อยู่</label>
                <textarea v-model="formData.addressC" row="1" type="text" class="form-control" name="addressC" :placeholder="formData.addressC"></textarea>
            </div>
            <div class="form-group col-6">
                <label for="idnumC">เลขที่ประจำตัวผู้เสียภาษีอากร</label>
                <input v-model="formData.idnumC" type="text" class="form-control" name="idnumC" :placeholder="formData.idnumC" >
            </div>
            <div class="form-group col-6">
                <label for="phoneC">โทรศัพท์</label>
                <input v-model="formData.phoneC" type="text" class="form-control" name="phoneC" :placeholder="formData.phoneC">
            </div>
            <div class="form-group col-6">
                <label for="faxC">แฟกซ์</label>
                <input v-model="formData.faxC" type="text" class="form-control" name="faxC" :placeholder="formData.faxC">
            </div>
          </div>
        </div>
  
    </section>

</template>


<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';

export default {
  name: 'EditHeaderQT',
  components: {
  },
  data: function () {
    return {
      formData:{ 
        nameU: '', 
        phoneU: '', 
        emailU: '',
        the1U: '',
        nameC: '',
        comnameC: '',
        addressC: '',
        idnumC: '',
        phoneC: '',
        faxC: ''
      },

      
    }
  },
  mounted() {
    if(this.showData == '1'){
    this.getQuotationHeader()
    }
  },
  props: [
    "tax",
    "dataEditHeaderQT",
    "showData",
    "page",
    "orderHistoryRef",
    "buCode"
  ],
  methods: {

    async getQuotationHeader(){
      try {
        let data = {
          REF:this.page == "checkout" ? this.CartRef : this.orderHistoryRef
        };
        let getAPI = await serviceAPI.call_API('post', 'checkout/ShowEditQuotation', data,'auth');
        let getData = getAPI.data.dbitems[0];
        if(getAPI.data.dbrows != 0 && getData.EDITADDR){
            let getEDITADDR = JSON.parse(getData.EDITADDR);
            this.formData = getEDITADDR;
          }
        
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }

    },
    async saveQuotationHeader(){
      try {
        let data = {
          REF:this.page == "checkout" ? this.CartRef : this.orderHistoryRef,
          JSON_DATA:this.formData
        };
        let getAPI = await serviceAPI.call_API('post', 'checkout/EditQuotation', data,'auth');
        let getData = getAPI.data.dbitems[0];
        serviceMain.showSuccessAlertWithTime(this, "บันทึกสำเร็จ",1200)
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }

    },

 
  },
  computed: {
    getBU:function(){
      return this.$store.getters['customer/getQTBU']
    },
    getQTCUSTINFO:function(){
      return this.$store.getters['customer/getQTCUSTINFO']
    },
    CartRef:function(){
      return this.$store.getters['customer/getQTCUSTREF']
    },
    headQT:function(){
      if(this.tax == true){
       this.formData = {
        nameU: '', 
        phoneU: '', 
        emailU: '',
        the1U: '',
        nameC: `${this.dataEditHeaderQT.NME ? this.dataEditHeaderQT.NME:'' + this.dataEditHeaderQT.LNME?this.dataEditHeaderQT.LNME:''}`,
        comnameC: `${this.dataEditHeaderQT.COM ? this.dataEditHeaderQT.COM: ''}`,
        addressC: `${this.dataEditHeaderQT.ADNUM?this.dataEditHeaderQT.ADNUM:''}`+
                  `${this.dataEditHeaderQT.BLD?' หมู่บ้าน/อาคาร '+this.dataEditHeaderQT.BLD:''}`+
                  `${this.dataEditHeaderQT.FLR !== '-'&&this.dataEditHeaderQT.FLR !== ''?' ชั้นที่ '+this.dataEditHeaderQT.FLR:''}`+
                  `${this.dataEditHeaderQT.ROM !== '-'&&this.dataEditHeaderQT.ROM !== ''?' ห้องที่ '+this.dataEditHeaderQT.ROM:''}`+
                  `${this.dataEditHeaderQT.MOO !== '-'&&this.dataEditHeaderQT.MOO !== ''?' หมู่ที่ '+this.dataEditHeaderQT.MOO:''}`+
                  `${this.dataEditHeaderQT.SOI !== '-'&&this.dataEditHeaderQT.SOI !== ''?' ซอย '+this.dataEditHeaderQT.SOI:''}`+
                  `${this.dataEditHeaderQT.ROAD !== '-'&&this.dataEditHeaderQT.ROAD !== ''?' ถนน '+this.dataEditHeaderQT.ROAD:''}`+
                  `${this.dataEditHeaderQT.TBN !== '-'&&this.dataEditHeaderQT.TBN !== ''?' ตำบล '+this.dataEditHeaderQT.TBN:''}`+
                  `${this.dataEditHeaderQT.DST !== '-'&&this.dataEditHeaderQT.DST !== ''?' อำเภอ '+this.dataEditHeaderQT.DST:''}`+
                  `${this.dataEditHeaderQT.PVN !== '-'&&this.dataEditHeaderQT.PVN !== ''?' จังหวัด '+this.dataEditHeaderQT.PVN:''}`+
                  `${this.dataEditHeaderQT.ZIP !== '-'&&this.dataEditHeaderQT.ZIP !== ''?' รหัสไปรษณีย์ '+this.dataEditHeaderQT.ZIP:''}`,
        idnumC: `${this.dataEditHeaderQT.IDN ? this.dataEditHeaderQT.IDN: ''}`,
        phoneC: `${this.dataEditHeaderQT.PHO ? this.dataEditHeaderQT.PHO:''}`,
        faxC: ''
       }
      } else if(this.tax == false){
        this.formData = { 
        nameU: '', 
        phoneU: '', 
        emailU: '',
        the1U: '',
        nameC: '',
        comnameC: '',
        addressC: '',
        idnumC: '',
        phoneC: '',
        faxC: ''
      }
      }
    }
  },
  watch: {
    headQT: function (){
      this.$emit('headerQT')
    }
  }

}

</script>

<style scoped>
.labelshowaddress {
  font-family: 'kittithadabold75regular';
}

.pshowaddress {
  border-bottom: 1px solid gray;
  height: 32px;
}



.form-group {
  margin-bottom: 5px;
}

label {
  margin-bottom: 0px;
  color: rgb(125, 125, 125);
  font-size: .9em;
}
.card{
  background-color: white;
  border:none;

}
.form-control {
  height: 1.5rem;
  padding: 2px 5px;
}
.select-address{
  padding:0px 15px; 
  font-family: 'kittithadabold75regular'; 
  color:black; 
  background-color: rgb(241, 241, 241);
  border-radius: 25px; 
}
input[err="true"]{
  border: 2px red solid !important;
}
select[err="true"]{
  border: 2px red solid !important;
}
.box-black{
  border:2px black solid !important;
  border-radius:8px;
}
</style>
